<template>
  <div class="product-home-list">
    <router-link :to="`/${product.url_key}`">
      <div class="product-home-image">
        <img :src="product.thumbnail.large" alt="" />
      </div>
      <div class="product-home-meta">
        <p class="title" :title="product.name">{{ productName }}</p>
        <p class="status" v-if="product.stock_status === 'IN_STOCK'">
          {{ $t("in_stock") }}
        </p>
        <p class="text-danger" v-else>{{ $t("out_of_stock") }}</p>
        <router-link :to="`/${product.url_key}`" class="more_information"
          >meer informatie</router-link
        >
      </div>
    </router-link>
    <div class="product-home-action">
      <div class="d-flex justify-content-between">
        <quantity-input
          :product="product"
          @change-qty="changeQty"
        ></quantity-input>
        <p class="price">
          {{
            formatCurrency(product.price_range.maximum_price.final_price.value)
          }}
        </p>
      </div>
      <button
        type="button"
        class="product-home-cart"
        @click="addToCart"
        :disabled="product.stock_qty === 0"
      >
        <font-awesome-icon icon="fa-solid fa-shopping-cart" />
        <span class="d-none d-md-block">{{ $t("add_to_cart") }}</span>
      </button>
      <div class="compare">
        <input type="checkbox" :id="product.url_key" />
        <label class="mb-0" :for="product.url_key">{{ $t("compare") }}</label>
      </div>
    </div>

    <!-- badges -->
    <div class="product-home-badges">
      <span
        class="product-home-label label-out"
        v-if="product.stock_qty === 0"
        >{{ $t("out_of_stock") }}</span
      >
      <!--     <span class="product-home-label label-out" v-if="product.stock_qty === 0">{{ $t('out_of_stock') }}</span>
            <span class="product-home-label label-top" v-if="product.top">{{ $t('top') }}</span>
            <span class="product-home-label label-sale" v-if="product.sale_price">{{ $t('sale') }}</span>
            <span class="product-home-label label-new" v-if="product.new">{{ $t('new') }}</span> -->
    </div>
    <!-- badges -->
  </div>
</template>

<script>
import QuantityInput from "@/esf_weert_mobilesupplies/core/components/elements/QuantityInput";

export default {
  props: {
    product: Object,
  },
  components: {
    QuantityInput,
  },
  data() {
    return {
      qty: 1,
      nameMaxLength: 20,
    };
  },
  computed: {
    productName() {
      if (this.product.name.length <= this.nameMaxLength) {
        return this.product.name;
      } else {
        const words = this.product.name.split(" ");
        let shortenedString = "";
        let count = 0;
        for (let i = 0; i < words.length; i++) {
          if (count + words[i].length <= this.nameMaxLength) {
            shortenedString += words[i] + " ";
            count += words[i].length;
          } else {
            break;
          }
        }
        return shortenedString.trim() + "...";
      }
    },
  },
  methods: {
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount);
    },
    changeQty: function (current) {
      this.qty = current;
    },
    async addToCart() {
      if (this.product.__typename == "SimpleProduct") {
        const item =
          '{data: {sku: "' +
          this.product.sku +
          '", quantity:' +
          this.qty +
          "}}  ";
        let retval = await this.$store.dispatch("cart/addToCart", {
          type: this.product.__typename,
          item: item,
        });

        if (retval == false) {
          const msg = {
            type: "danger",
            title: this.$t("shopping_basket"),
            text: this.$t("not_add_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        } else {
          const msg = {
            type: "success",
            title: this.$t("shopping_basket"),
            text: this.$t("added_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        }
      } else {
        this.$router.push("/" + this.product.url_key + "/");
      }
    },
  },
};
</script>
