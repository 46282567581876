<template>
  <div>
    <p class="mb-0" v-if="!isExpanded" v-html="shortText">{{ shortText }}</p>
    <p class="mb-0" v-else v-html="text">{{ text }}</p>
    <a style="cursor: pointer" v-on:click="toggleText">
      {{ isExpanded ? $t("read_less") : $t("read_more")+'...' }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    maxChars: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleText() {
      this.isExpanded = !this.isExpanded;
    },
  },
  computed: {
    shortText() {
      return this.text.substr(0, this.maxChars);
    },
  },
};
</script>
